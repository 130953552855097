<template>
  <Connect class="connect_view">
    <ul
      v-if="dataList && (titleKeyName ? dataList[titleKeyName] : dataList.title)"
      class="list_connect"
    >
      <li :class="{ disabled: isDisabled }">
        <span class="ico_account ico_connect_sm" />
        <button
          v-if="!isDisabled"
          type="button"
          class="btn_connect"
          @click="$emit('onClickConnect', dataList)"
        >
          <span class="tit_connect">{{
            titleKeyName ? dataList[titleKeyName] : dataList.title
          }}</span>
          <span class="ico_account ico_arr_connect">상세화면 보기</span>
        </button>
        <span v-else class="tit_connect">{{
          titleKeyName ? dataList[titleKeyName] : dataList.title
        }}</span>
      </li>
    </ul>
  </Connect>
</template>

<script>
import Connect from "@/components/common/connect/Connect";

export default {
  name: "ConnectViewOnlyOne",
  components: {
    Connect,
  },
  props: {
    dataList: Object,
    titleKeyName: String,
    isDisabled: Boolean,
  },
};
</script>
